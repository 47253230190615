<template>
  <div class="datasource-modal">
    <el-dialog
      width="40%"
      start
      :title="title"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :before-close="handleClose">
      <el-form
        label-position="top"
        :model="createDataSource"
        status-icon
        ref="createDataSource"
        label-width="120px"
        class="demo-ruleForm"
        :hide-required-asterisk="true"
        :rules="rules">
        <i class="mdi mdi-help-circle"></i>
        <el-form-item label="Datasource Name" prop="name">
          <el-input
            type="text"
            v-model="createDataSource.name"
            autocomplete="off"></el-input>
        </el-form-item>
        <i class="mdi mdi-help-circle icon1"></i>
        <el-form-item label="Database Name" prop="database">
          <el-input
            type="text"
            v-model="createDataSource.database"
            autocomplete="off"></el-input>
        </el-form-item>
        <i class="mdi mdi-help-circle icon2"></i>
        <el-form-item label="Host" prop="host">
          <el-input
            type="text"
            v-model="createDataSource.host"
            autocomplete="off"></el-input>
        </el-form-item>
        <i class="mdi mdi-help-circle icon3"></i>
        <el-form-item label="Username" prop="user">
          <el-input
            type="text"
            v-model="createDataSource.user"
            autocomplete="off"></el-input>
        </el-form-item>
        <i class="mdi mdi-help-circle icon4"></i>
        <el-form-item label="Password" prop="password">
          <el-input
            type="password"
            v-model="createDataSource.password"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            class="btn-primary"
            :loading="loading"
            type="info"
            @click="created('createDataSource')"
            @keypress="created('createDataSource')"
            style="width:100%;">{{ state === 'create' ? 'Create': 'Save' }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ApiBACKUPS from '@/lib/ApiBACKUPS';

export default {
  name: 'DataSourceModal',
  props: ['showModal', 'title', 'state', 'id'],
  data() {
    return {
      dialogVisible: this.showModal,
      loading: false,
      disabled: false,
      createDataSource: {
        type: 'mysql',
      },
      rules: {
        name: [
          {
            type: 'string',
            required: true,
            message: 'Please enter Datasource name.',
            trigger: 'blur',
          },
        ],
        database: [
          {
            type: 'string',
            required: true,
            message: 'Please enter Database.',
            trigger: 'blur',
          },
        ],
        host: [
          {
            type: 'string',
            required: true,
            message: 'Please enter Hostname.',
            trigger: 'blur',
          },
        ],
        user: [
          {
            type: 'string',
            required: true,
            message: 'Please enter Username.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
      }
    },
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    handleClose() {
      this.$root.$emit('closeModal');
    },
    async getDatasource() {
      try {
        const r = await ApiBACKUPS.getOne(`/datasources/${this.id}`);
        if (r.success) {
          this.createDataSource = { ...r.data };
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    created(form) {
      this.loading = true;
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.state === 'create') {
            return this.sendCreate();
          }
          if (this.state === 'update') {
            return this.sendUpdate();
          }
        }
        this.loading = false;
        return false;
      });
    },
    reset(form) {
      this.$refs[form].resetFields();
    },
    async sendCreate() {
      try {
        const r = await ApiBACKUPS.post('/datasources/', this.createDataSource);
        if (r.success) {
          this.loading = false;
          this.dialogVisible = false;
          this.reset('createDataSource');

          this.$root.$emit('reloadDataSources');
        }

        if (r.status === 422) {
          this.$message({
            message: r.message,
            type: 'warning',
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async sendUpdate() {
      try {
        this.loading = true;
        const r = await ApiBACKUPS.put(`/datasources/${this.id}`, this.createDataSource);
        if (r.success) {
          this.dialogVisible = false;
          this.reset('createDataSource');
          this.$root.$emit('reloadDataSources');
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    if (this.state === 'update') {
      this.getDatasource();
    }
  },
};
</script>

<style lang="scss">
.datasource-modal{
  .el-dialog{
    height: auto;
    margin-top: 8vh !important;
    border-radius: 8px;
    padding: 40px 40px 26px 40px;
    @media (min-width: 720px){
      padding: 80px 80px 46px 80px;
    }
  }
  .el-dialog__header{
    padding: 0;
    margin-bottom: 29px;
  }
  .el-dialog__body{
    padding: 0;
  }

  .el-form--label-top .el-form-item__label {
  padding: 0 !important;
}

.el-form-item__label {
  // line-height: 30px !important;
  // color: #214BEB !important;
  // font-weight: 700;
  // letter-spacing: -0.01em;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  letter-spacing: -0.01em;
}
.el-form-item{
  margin-bottom: 10px;
}
.el-form-item__content{
  height: 40px;
  .el-input{
    height: 30px;
  }
  .el-input__inner{
    height: 30px;
  }
}
.el-form-item__input{
  height: 20px !important;
}

.el-form-item__error{
  padding: 0;
  font-size: 10px;
}

.el-button{
  width: 129px !important;
  height: 44px;
  position: absolute !important;
  right: 0 !important;
  @media (min-width: 720px){
    width: 179px !important;
  }
}

.mdi-help-circle{
  position: absolute;
  left: 165px;
  margin-top: 3px;
  color: #969696;
  @media (min-width: 720px){
    left: 205px;
  }
}

.icon1{
  left: 153px;
  @media (min-width: 720px){
    left: 193px;
  }
}
.icon2{
  left: 78px;
  @media (min-width: 720px){
    left: 119px;
  }
}
.icon3{
  left: 115px;
  @media (min-width: 720px){
    left: 155px;
  }
}
.icon4{
  left: 112px;
  @media (min-width: 720px){
    left: 152px;
  }
}
}

</style>
