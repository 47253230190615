<template>
  <div class="configuration-modal">
    <el-dialog
      :fullscreen="true"
      start
      :title="title"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :before-close="handleClose">
      <el-form
        label-position="top"
        :model="createConfiguration"
        status-icon
        ref="createConfiguration"
        label-width="120px"
        class="demo-ruleForm"
        :hide-required-asterisk="true"
        :rules="rules">

        <div class="principal">
          <el-form-item label="Active" prop="active">
            <el-switch v-model="createConfiguration.active"></el-switch>
          </el-form-item>

          <el-form-item>
            <el-button
              class="btn-primary"
              :loading="loading"
              type="info"
              @click="created('createConfiguration')"
              @keypress="created('createConfiguration')"
              style="width:100%;">{{ state === 'create' ? 'Create': 'Save' }}
            </el-button>
          </el-form-item>
        </div>

        <div class="conf">
          <div>
            <el-form-item label="Datasource" prop="datasourceId">
              <el-select
                v-model="createConfiguration.datasourceId"
                clearable
                filterable
                placeholder="Select Data Source">
                  <el-option
                    v-for="item in dataSources"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Interval" prop="interval">
              <el-select
                v-model="createConfiguration.interval"
                clearable
                filterable
                placeholder="Select Interval">
                  <el-option
                    v-for="item in intervals"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key">
                  </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ApiBACKUPS from '@/lib/ApiBACKUPS';

export default {
  name: 'ConfigurationModal',
  props: ['showModal', 'title', 'state', 'id'],
  components: {},
  data() {
    return {
      dataSources: '',
      dialogVisible: this.showModal,
      loading: false,
      disabled: false,
      createConfiguration: {},
      intervals: [
        {
          key: '5',
          value: '5 Minutes',
        },
        {
          key: '30',
          value: '30 Minutes',
        },
        {
          key: '60',
          value: '1 Hour',
        },
        {
          key: '120',
          value: '2 Hour',
        },
        {
          key: '180',
          value: '3 Hour',
        },
        {
          key: '240',
          value: '4 Hour',
        },
        {
          key: '300',
          value: '5 Hour',
        },
        {
          key: '360',
          value: '6 Hour',
        },
        {
          key: '720',
          value: '12 Hour',
        },
        {
          key: '1440',
          value: '24 Hour',
        },
      ],
      rules: {
        datasourceId: [
          {
            required: true,
            message: 'Please select a data source.',
            trigger: 'blur',
          },
        ],
        interval: [
          {
            required: true,
            message: 'Please select an interval.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.$root.$emit('closeModal');
      }
    },
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    async getDataSources() {
      try {
        const res = await ApiBACKUPS.get('/datasources');
        this.dataSources = res.data ?? [];
      } catch (error) {
        console.error(error);
      }
      return null;
    },
    handleClose() {
      this.$root.$emit('closeModal');
    },
    async getConfiguration() {
      try {
        const r = await ApiBACKUPS.getOne(`/configurations/${this.id}`);
        if (r.success) {
          this.createConfiguration = { ...r.data };
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    created(form) {
      this.loading = true;
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.state === 'create') {
            return this.sendCreate();
          }
          if (this.state === 'update') {
            return this.sendUpdate();
          }
        }
        this.loading = false;
        return false;
      });
    },
    reset(form) {
      this.$refs[form].resetFields();
    },
    async sendCreate() {
      try {
        const r = await ApiBACKUPS.post('/configurations/', this.createConfiguration);
        if (r.success) {
          this.loading = false;
          this.dialogVisible = false;
          this.reset('createConfiguration');

          this.$root.$emit('reloadData');
        }

        if (r.status === 422) {
          this.$message({
            message: r.message,
            type: 'warning',
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async sendUpdate() {
      try {
        this.loading = true;
        const r = await ApiBACKUPS.put(`/configurations/${this.id}`, this.createConfiguration);
        if (r.success) {
          this.dialogVisible = false;
          this.reset('createConfiguration');
          this.$root.$emit('reloadData');
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    if (this.state === 'update') {
      this.getConfiguration();
    }
    this.getDataSources();
  },
};
</script>

<style lang="scss">
  .el-picker-panel{
    font-family: 'Work Sans' !important;
  }
.configuration-modal{

  .principal{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .el-date-editor{
    font-family: 'Work Sans' !important;
    width: 100% !important;
  }

  .conf{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
  }

  .el-dialog{
    height: auto;
    margin-top: 8vh !important;
    border-radius: 8px;
    padding: 40px 40px 26px 40px;
    @media (min-width: 720px){
      padding: 80px 80px 46px 80px;
    }
  }
  .el-dialog__header{
    padding: 0;
    margin-bottom: 29px;
  }
  .el-dialog__body{
    padding: 0;
  }

  .el-form--label-top .el-form-item__label {
    padding: 0 !important;
  }

  .el-form-item__label {
    // line-height: 30px !important;
    // color: #214BEB !important;
    // font-weight: 700;
    // letter-spacing: -0.01em;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    letter-spacing: -0.01em;
  }
  // .el-form-item{
  //   margin-bottom: 10px;
  // }
  // .el-form-item__content{
  //   height: 40px;
  //   .el-input{
  //     height: 30px;
  //   }
  //   .el-input__inner{
  //     height: 30px;
  //   }
  // }

  .el-button{
    width: 129px !important;
    height: 44px;
    @media (min-width: 720px){
      width: 179px !important;
    }
  }
}

</style>
