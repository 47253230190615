import VueJWT from 'vuejs-jwt';
import UUID from 'vue-uuid';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import Vue from 'vue';
import vueCustomElement from 'vue-custom-element';
import VueTour from 'vue-tour';
import store from './store';
import router from './router';
import App from './App.vue';

Vue.config.productionTip = false;

// configure language
locale.use(lang);
Vue.use(ElementUI);
Vue.use(UUID);
require('vue-tour/dist/vue-tour.css');

Vue.use(VueTour);
Vue.use(VueJWT);
Vue.use(vueCustomElement);

App.store = store;
App.router = router;

Vue.customElement('backups-cms', App, {
  shadow: false,
});
