<template>
  <div class="edit-profile">
    <div class="content-modal-user">
      <el-form
        label-position="top"
        :model="userDataForm"
        status-icon
        ref="userDataForm"
        label-width="120px"
        class="demo-ruleForm"
        :hide-required-asterisk="true"
        :rules="rules">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Update your photo and personal details here" prop="profilePicture">
              <custom-upload v-model="userDataForm.profilePicture" :edit="true"></custom-upload>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="12" :xl="12">
            <el-form-item label="First Name" prop="firstname">
              <el-input
                type="text"
                v-model="userDataForm.firstname"
                autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="12" :xl="12">
            <el-form-item label="Last Name" prop="lastname">
              <el-input
                type="text"
                v-model="userDataForm.lastname"
                autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="12" :xl="12">
            <el-form-item label="Email" prop="email">
              <el-input
                type="email"
                v-model="userDataForm.email"
                prefix-icon="mdi mdi-email"
                autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="12" :xl="12">
            <el-form-item label="Password" prop="password">
              <el-input
                type="password"
                value="**********"
                autocomplete="off"></el-input>
              <span class="edit-pass">
                <i class="mdi mdi-pencil"
                   @click="showPasswordModal(true)"
                   @keydown="showPasswordModal(true)"
                ></i>
              </span>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="footer">
          <el-form-item>
            <el-button
              class="btn-secondary cancel"
              type="info"
              @click="$router.push({path: '/'})"
              @keypress="$router.push({path: '/'})">
              Cancel
            </el-button>
            <el-button
              class="btn-secondary submit"
              :loading="loading"
              type="info"
              @click="action('userDataForm')"
              @keypress="action('userDataForm')"
              style="width:100%;">Save
            </el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <reset-password-modal
      v-if="showResetPassword"
      :showModal="showResetPassword"
      title="Change your Password"
    >
    </reset-password-modal>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import ResetPasswordModal from '@/components/Modals/ResetPasswordModal.vue';
import ApiSSO from '../../lib/ApiSSO';
import CustomUpload from '../Uploads/CustomUpload.vue';

export default {
  name: 'EditProfile',
  components: {
    CustomUpload,
    ResetPasswordModal,
  },
  data() {
    return {
      userDataForm: {
        active: 1,
        applications: [],
        deleteRoles: [],
        addRoles: [],
        newRoles: [],
      },
      rolesCopy: [],
      rules: {
        firstname: [
          {
            type: 'string',
            required: true,
            message: 'Please enter first name.',
            trigger: 'blur',
          },
        ],
        lastaname: [
          {
            type: 'string',
            required: true,
            message: 'Please enter last name.',
            trigger: 'blur',
          },
        ],
        email: [
          {
            type: 'email',
            required: true,
            message: 'Please enter email.',
            trigger: 'blur',
          },
        ],
      },
      loading: false,
      showResetPassword: false,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    ...mapMutations(['setUser']),
    async getUser() {
      try {
        const r = await ApiSSO.get2(`/users/${this.user.id}`);
        if (r.success) {
          this.userDataForm = {
            ...r.data, deleteRoles: [], addRoles: [], newRoles: [],
          };
          if (!this.userDataForm.roles || this.userDataForm.roles == null) {
            this.userDataForm.roles = [];
          } else if (typeof this.userDataForm.roles === 'string') {
            this.userDataForm.roles = this.userDataForm.roles.split(',');
          }
          if (this.userDataForm.applications) {
            this.userDataForm.applications = this.userDataForm.applications.split(',');
          }
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    showPasswordModal(data) {
      this.showResetPassword = data !== undefined ? data : false;
    },
    async sendUpdate() {
      try {
        this.loading = true;
        this.userDataForm.id = this.user.id;
        const r = await ApiSSO.put('/users', this.userDataForm);
        if (r.success) {
          this.setUser({
            name: `${r.data.data.firstname} ${r.data.data.lastname}`,
            image: r.data.data.profilePicture,
          });
          console.log('Update', r);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    action(form) {
      this.loading = true;
      this.$refs[form].validate((valid) => {
        if (valid) {
          return this.sendUpdate();
        }
        this.loading = false;
        return false;
      });
    },
  },
  mounted() {
    this.getUser();
    this.$root.$on('closeModal', this.showPasswordModal);
  },
};
</script>

<style lang="scss">
.edit-profile {
  .content-modal-user {
    .custom-upload {
      .el-upload {
        width: 100% !important;
      }
      .avatar {
        object-fit: contain;
      }
      .avatar-uploader .el-upload {
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        padding: 30px 0;
        height: 100%;

        p {
          font-family: 'Work Sans';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: #4E4C4C;
          width: 37%;
          @media (max-width: 768px) {
            width: 100%;
          }
          margin: 0 auto;
        }
      }

      .avatar .el-upload {
        width: auto;
      }

      .avatar-uploader-icon {
        width: 100%;
        height: 50px;
      }
    }

    .avatar-uploader {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 227px;
    }

    .el-form-item__content {
      position: relative;
    }

    .el-form-item__label {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      color: #3C3A3A;
      margin-bottom: 10px;
    }

    span.edit-pass {
      position: absolute;
      z-index: 1;
      right: 13px;
      color: #9C9C9C;
      cursor: pointer;
    }

    .footer {
      height: 80px;
      background: #FFFFFF;
      border-top: 1px solid #E5E5E5;
      margin-top: 50px;
      display: flex;
      justify-content: end;
      padding-top: 20px;

      .el-form-item__content {
        display: flex;
      }

      .cancel {
        width: 96px;
        height: 36px;
        background: #F1F1F1;
        border-radius: 4px;
        border: 0;
        color: #363738;
      }

      .submit {
        width: 134px;
        height: 36px;
        background: #B5BABC;
        border-radius: 4px;
        border: 0;
        color: #FFFFFF;
      }
    }
  }

  .el-input__icon {
    margin-left: 6px;
  }
}
</style>
