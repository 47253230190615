<template>
  <div class="container-breadcrumb">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        v-for="(item, i) in items"
        :key="`item-${i}`"
        :class="item.active ? 'active' : ''"
        :to="item.link"
      >
        <template v-if="item.type === 'image'">
          <img width="117px" :src="item.imgUrl ?? ImgNotFound" alt="logo">
        </template>
        <template v-else>
          {{ item.string }}
        </template>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>

import ImgNotFound from '@/assets/img_not_found.png';

export default {
  name: 'BreadcrumbView',
  props: ['items'],
  data() {
    return {
      ImgNotFound,
    };
  },
  methods: {
  },
};
</script>

<style lang="scss">

.container-breadcrumb{
  display: none;
  @media (min-width: 720px){
    display: block;
  }

span.el-breadcrumb__item.active span {
  font-weight: 400 !important;
  color: #000000 !important;
  letter-spacing: -0.01em;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 23.3333px;
}

  .is-link{
    font-weight: 200 !important;
  }

  .el-breadcrumb{
    margin: 13px 0 0 0;
    display: flex;
    align-items: center;
    @media (min-width: 720px){
      margin: 13px 0 0 56px;
    }
    &__item{
      display: flex;
      align-items: center;
      i{
        color: #000000;
        font-size: 33px;
        font-weight: 900;
        @media (min-width: 720px){
          margin: 0 20px;
        }
      }

    }
  }
}

</style>
